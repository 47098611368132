import { useState } from 'react'
import style from './Chat.module.css'
import ChatInput from './ChatInput'
import ChatInterface from './ChatInterface'
import useDraggable from '../../../hooks/useDraggable'
import { useFilesContext } from '../../../contexts/FilesContext'
import DropZoneIcon from '../../../assets/svgs/DropIcon'
import { QuickchatData } from '../../../dummyData'
import { useHistoryContext } from '../../../contexts/HistoryContext'

const Chat = () => {
  // formData now includes both message and a hidden cardUrl
  const [formData, setFormData] = useState({ message: '', cardUrl: '' })
  const [openUpload, setOpenUpload] = useState(false)
  const [loading, setLoading] = useState(false)
  const { addRequest, addResponse, history } = useHistoryContext()
  const { draggable, onDragStart, onDragEnd, isDragging } = useDraggable()
  const { addFile, files } = useFilesContext()

  // When a draggable card is dropped, update formData with its URL.
  const handleDrop = (e) => {
    e.preventDefault()
    const data = e.dataTransfer.getData('application/json')
    if (data) {
      const item = JSON.parse(data)
      // Update hidden cardUrl (it will not be visible in the input)
      setFormData(prev => ({ ...prev, cardUrl: item.url }))
      addFile(item)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  // When sending the message, append the dropped card URL (if any) to the message.
  const handleResponses = async (message) => {
    setLoading(true)
    // Combine the user message with the hidden cardUrl if it exists.
    const finalMessage = message + (formData.cardUrl ? ` ${formData.cardUrl}` : '')
    addRequest(finalMessage)

    try {
      const response = await fetch('https://chimpknows.com/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: finalMessage })
      })

      if (response.ok) {
        const responseData = await response.json()
        const responseMessage = responseData.response.join('\n\n')
        addResponse(responseMessage)
        setLoading(false)
        // Clear the hidden URL after sending.
        setFormData({ message: '', cardUrl: '' })
      } else {
        console.error('Response not OK:', response.statusText)
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  return (
    <div
      className={style.container}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <p className={style.title}>Chimp Quickchat</p>
      {history.length > 0 ? (
        <ChatInterface
          smallInterface={true}
          history={history}
          loading={loading}
          handleResponses={handleResponses}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          {!isDragging ? (
            <div className={style.cardsContainer}>
              {QuickchatData.map((card, index) => (
                <div
                  className={style.card}
                  key={index}
                  onClick={() => handleResponses(card.title)}
                >
                  <p className={style.cardTitle}>{card.title}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className={style.dropZone}>
              <DropZoneIcon />
              <p className={style.dropZoneText}>
                Drag & drop items from dashboard to discuss with Chimp
              </p>
            </div>
          )}
        </div>
      )}
      <ChatInput
        setOpenUpload={setOpenUpload}
        openUpload={openUpload}
        handleResponses={handleResponses}
        setFormData={setFormData}
        formData={formData}
      />
    </div>
  )
}

export default Chat
