import { useEffect, useState } from 'react'
import style from './Feed.module.css'
import FeedCard from './FeedCard'
import FilterIcon from '../../../assets/svgs/Filter'
import useDroppable from '../../../hooks/useDroppable'

const Feed = () => {
  const filters = [
    'All',
    'Jobs',
    'Courses',
    // 'Contacts',
    // 'Events',
    // 'News',
    // 'Chimp',
    'Saved'
  ]

  const [selectedFilters, setSelectedFilters] = useState([])
  const [feed, setFeed] = useState([]) // start with an empty array
  const [openFilter, setOpenFilter] = useState(false)
  const droppableProps = useDroppable(feed, setFeed)

  useEffect(() => {
    // Use the 7-day jobs endpoint with pagination via the offset parameter.
    const offsets = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220]
    const baseJobsUrl =
      "https://free-y-combinator-jobs-api.p.rapidapi.com/active-jb-7d"
    const jobsPromises = offsets.map((offset) => {
      const url = `${baseJobsUrl}?offset=${offset}`
      return fetch(url, {
        method: "GET",
        headers: {
          "x-rapidapi-host": "free-y-combinator-jobs-api.p.rapidapi.com",
          "x-rapidapi-key":
            "3275cb612cmshb12f8e9771d5a89p1f6b8fjsnd562cdf74bf3"
        }
      }).then((res) => res.json())
    })

    // Courses endpoint: we'll fetch pages 1 through 25.
    const coursesBaseUrl =
      "https://collection-for-coursera-courses.p.rapidapi.com/rapidapi/course/get_course.php"
    const coursePromises = Array.from({ length: 25 }, (_, i) => {
      const pageNo = i + 1
      const url = `${coursesBaseUrl}?page_no=${pageNo}`
      return fetch(url, {
        method: "GET",
        headers: {
          "x-rapidapi-host": "collection-for-coursera-courses.p.rapidapi.com",
          "x-rapidapi-key":
            "3275cb612cmshb12f8e9771d5a89p1f6b8fjsnd562cdf74bf3"
        }
      }).then((res) => res.json())
    })

    // Wait for all jobs pages and flatten the results.
    const jobsPromiseAll = Promise.all(jobsPromises).then((results) =>
      results.flat()
    )
    const coursesPromiseAll = Promise.all(coursePromises)

    Promise.all([jobsPromiseAll, coursesPromiseAll])
      .then(([jobsData, coursesPages]) => {
        // Transform jobs data
        const transformedJobs = jobsData.map((job) => {
          const formattedDate = new Date(job.date_posted)
            .toISOString()
            .split("T")[0]

          const salaryData =
            job.salary_raw && job.salary_raw.value ? job.salary_raw.value : {}
          const minValue = salaryData.minValue || "N/A"
          const maxValue = salaryData.maxValue || "N/A"
          const currency =
            job.salary_raw && job.salary_raw.currency ? job.salary_raw.currency : ""
          const unitText = salaryData.unitText || ""

          const formattedEmployment =
            job.employment_type && job.employment_type.length > 0
              ? job.employment_type
                  .map((type) =>
                    type
                      .replace(/_/g, " ")
                      .toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())
                  )
                  .join(", ")
              : "N/A"

          const location =
            job.locations_derived && job.locations_derived.length > 0
              ? job.locations_derived[0]
              : job.locations_raw && job.locations_raw.length > 0
              ? `${job.locations_raw[0].address.addressLocality}, ${job.locations_raw[0].address.addressCountry}`
              : "Unknown Location"

          const details = (
            <>
              <span>
                Salary Type: {minValue}/{maxValue} {currency} ({unitText})
              </span>
              <br />
              <span>Employment Type: {formattedEmployment}</span>
              <br />
              <span>Location: {location}</span>
            </>
          )

          return {
            id: job.id,
            category: 'Jobs', // all API jobs are jobs
            title: job.title,
            company: job.organization,
            datePosted: formattedDate, // date only
            summary: job.organization_url
              ? `Visit ${job.organization_url}`
              : '',
            description: details, // details as a React element
            url: job.url, // job URL for navigation
            seen: false
          }
        })

        // Merge all course reviews from all pages
        const allCoursesReviews = coursesPages.reduce((acc, pageData) => {
          return acc.concat(pageData.reviews || [])
        }, [])

        const transformedCourses = allCoursesReviews.map((course) => {
          return {
            id: course.course_id,
            category: 'Courses',
            title: course.course_name,
            company: course.course_institution,
            datePosted: "", // Courses API does not include a posting date
            summary: `Course by ${course.course_institution}`,
            description: ``,
            url: course.course_url, // course URL for navigation
            seen: false
          }
        })

        setFeed([...transformedJobs, ...transformedCourses])
      })
      .catch((error) => {
        console.error("Error fetching data: ", error)
      })
  }, [])

  useEffect(() => {
    console.log("Fetched feed data:", feed)
  }, [feed])

  useEffect(() => {
    if (selectedFilters.length === 0) {
      setSelectedFilters(["All"])
    }
  }, [selectedFilters, feed])

  const handleSelect = (filter) => {
    if (filter === "All") {
      setSelectedFilters([])
    } else {
      const index = selectedFilters.indexOf(filter)
      if (index === -1) {
        if (selectedFilters.includes("All")) {
          setSelectedFilters([filter])
        } else {
          setSelectedFilters([...selectedFilters, filter])
        }
      } else {
        setSelectedFilters(selectedFilters.filter((f) => f !== filter))
      }
    }
  }

  const isFiltered = (item) => {
    return (
      selectedFilters.length === 0 ||
      selectedFilters.includes("All") ||
      selectedFilters.includes(item.category)
    )
  }

  // Date filtering functions (only applicable for jobs)
  const isToday = (someDate) => {
    const today = new Date()
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    )
  }

  const isYesterday = (someDate) => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return (
      someDate.getDate() === yesterday.getDate() &&
      someDate.getMonth() === yesterday.getMonth() &&
      someDate.getFullYear() === yesterday.getFullYear()
    )
  }

  const isThisWeek = (someDate) => {
    const today = new Date()
    const firstDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    )
    const lastDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + (6 - today.getDay())
    )
    const isBeforeWeek = someDate < firstDayOfWeek
    const isAfterWeek = someDate > lastDayOfWeek
    const isTodayOrYesterday = isToday(someDate) || isYesterday(someDate)
    return !isTodayOrYesterday && !isBeforeWeek && !isAfterWeek
  }

  const isThisMonth = (someDate) => {
    const today = new Date()
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    const isBeforeMonth = someDate < firstDayOfMonth
    const isAfterMonth = someDate > lastDayOfMonth
    const isWithinWeek =
      isToday(someDate) || isYesterday(someDate) || isThisWeek(someDate)
    return !isWithinWeek && !isBeforeMonth && !isAfterMonth
  }

  const isEarlierThisYear = (someDate) => {
    const today = new Date()
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31)
    return (
      someDate >= firstDayOfYear &&
      someDate <= lastDayOfYear &&
      !isThisMonth(someDate)
    )
  }

  // Determine whether to show job grouping sections.
  // If "Courses" is selected but "Jobs" is not, we hide the job groups.
  const showJobGroups = !selectedFilters.includes("Courses") || selectedFilters.includes("Jobs")

  return (
    <div className={style.container}>
      <p className={style.title}>Feed</p>
      <div className={style.filterContainer}>
        {filters.map((filter, index) => (
          <div
            className={`${style.filter} ${
              selectedFilters.includes(filter) ? style.selected : ""
            }`}
            key={index}
            onClick={() => {
              filter === "All" ? handleSelect("All") : handleSelect(filter)
            }}
          >
            {filter}
          </div>
        ))}
      </div>
      <div
        onClick={() => setOpenFilter(!openFilter)}
        className={style.mobileFilters}
      >
        {openFilter ? "" : <p className={style.filterTitle}>Filter</p>}
        {selectedFilters.length > 0 && !selectedFilters.includes("All") && (
          <div className={style.filterBubble}>{selectedFilters.length}</div>
        )}
        <FilterIcon />
      </div>
      {openFilter && (
        <div className={style.filterContainerMobile}>
          {filters.map((filter, index) => (
            <div
              className={`${style.filter} ${
                selectedFilters.includes(filter) ? style.selected : ""
              }`}
              key={index}
              onClick={() => {
                filter === "All" ? handleSelect("All") : handleSelect(filter)
              }}
            >
              {filter}
            </div>
          ))}
        </div>
      )}
      <div className={style.cardsContainer}>
        {/* Render job groups only if showJobGroups is true */}
        {showJobGroups && (
          <>
            {feed.some(
              (item) =>
                item.category === "Jobs" &&
                isToday(new Date(item.datePosted || new Date()))
            ) && (
              <div className={style.filteredFeed}>
                <p className={style.timetext}>Today:</p>
                {feed
                  .filter(
                    (item) =>
                      item.category === "Jobs" &&
                      isToday(new Date(item.datePosted || new Date()))
                  )
                  .map((feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard feedItem={feedItem} index={index} />
                      </div>
                    )
                  )}
              </div>
            )}
            {feed.some(
              (item) =>
                item.category === "Jobs" &&
                isYesterday(new Date(item.datePosted || new Date()))
            ) && (
              <div className={style.filteredFeed}>
                <p className={style.timetext}>Yesterday:</p>
                {feed
                  .filter(
                    (item) =>
                      item.category === "Jobs" &&
                      isYesterday(new Date(item.datePosted || new Date()))
                  )
                  .map((feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard feedItem={feedItem} index={index} />
                      </div>
                    )
                  )}
              </div>
            )}
            {feed.some(
              (item) =>
                item.category === "Jobs" &&
                isThisWeek(new Date(item.datePosted || new Date()))
            ) && (
              <div className={style.filteredFeed}>
                <p className={style.timetext}>This Week:</p>
                {feed
                  .filter(
                    (item) =>
                      item.category === "Jobs" &&
                      isThisWeek(new Date(item.datePosted || new Date()))
                  )
                  .map((feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard feedItem={feedItem} index={index} />
                      </div>
                    )
                  )}
              </div>
            )}
            {feed.some(
              (item) =>
                item.category === "Jobs" &&
                isThisMonth(new Date(item.datePosted || new Date()))
            ) && (
              <div className={style.filteredFeed}>
                <p className={style.timetext}>This Month:</p>
                {feed
                  .filter(
                    (item) =>
                      item.category === "Jobs" &&
                      isThisMonth(new Date(item.datePosted || new Date()))
                  )
                  .map((feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard feedItem={feedItem} index={index} />
                      </div>
                    )
                  )}
              </div>
            )}
            {feed.some(
              (item) =>
                item.category === "Jobs" &&
                isEarlierThisYear(new Date(item.datePosted || new Date()))
            ) && (
              <div className={style.filteredFeed}>
                <p className={style.timetext}>Earlier This Year:</p>
                {feed
                  .filter(
                    (item) =>
                      item.category === "Jobs" &&
                      isEarlierThisYear(new Date(item.datePosted || new Date()))
                  )
                  .map((feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard feedItem={feedItem} index={index} />
                      </div>
                    )
                  )}
              </div>
            )}
          </>
        )}
        {/* Render courses separately */}
        {feed.some((item) => item.category === "Courses") && (
          <div className={style.filteredFeed}>
            <p className={style.timetext}>Courses:</p>
            {feed
              .filter((item) => item.category === "Courses")
              .map((feedItem, index) =>
                isFiltered(feedItem) && (
                  <div
                    key={index}
                    onDrop={(e) => droppableProps.onDrop(e, index)}
                    onDragOver={droppableProps.onDragOver}
                  >
                    <FeedCard feedItem={feedItem} index={index} />
                  </div>
                )
              )}
          </div>
        )}
      </div>
      <p className={style.scrollText}>Scroll to see more</p>
    </div>
  )
}

export default Feed
