import { createContext, useContext, useState } from 'react';

// Create the LayoutContext to hold layout-related state and functions
const LayoutContext = createContext();

// Custom hook to use the LayoutContext
export const useLayoutContext = () => useContext(LayoutContext);

// LayoutProvider component to provide the LayoutContext to its children
export const LayoutProvider = ({ children }) => {
  // Initialize layout as an empty array to ensure it's iterable.
  const [layout, setLayout] = useState([]);
  // URL for the API endpoint, defaulting to an empty string if not defined.
  const url = process.env.REACT_APP_API_URL || '';

  // Function to fetch profile layout from the server
  const fetchProfileLayout = async (id) => {
    try {
      const response = await fetch(`${url}/users/getProfile/${id}`);
      const data = await response.json();
      if (data.layout) {
        setLayout(data.layout);
      }
    } catch (error) {
      console.error('Error fetching profile layout:', error);
    }
  };

  // Function to save the current layout to the server
  const saveLayout = (newComponents, userId) => {
    fetch(`${url}/users/saveProfile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userId,
        layout: newComponents,
      }),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error saving profile:', error);
      });
  };

  // Function to add a new component to the layout.
  // Since layout is now an array, the spread operator works correctly.
  const addComponent = (id, userId) => {
    const existingComponent = layout.some((item) => item.id === id);

    if (!existingComponent) {
      const newComponent = { id: id, type: 'full' };
      const updatedComponents = [...layout, newComponent];

      setLayout(updatedComponents);
      saveLayout(updatedComponents, userId);
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        layout,
        saveLayout,
        fetchProfileLayout,
        addComponent,
        setLayout,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
